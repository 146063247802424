import React, {useEffect, useRef, useState} from 'react';

import TimesheetTable from '../timesheets_time_row_table';
import {createRefetchContainer, graphql} from 'react-relay';
import {withSocketHandling} from '../../../../../../socket/withSocketHandling';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
	getCombinedSwitchList,
	getEntityList,
	getGroupedMovedTasks,
	getSuggestedSwitchTasks,
	getSwitchIdleTimes,
	getSwitchTasks,
	getTimeRegsMoved,
	getTopSection,
} from '../timesheet-table-util/DataBundlingUtil';
import {
	getDayRegsForSwitchEntity,
	getFilteredSwitchIdleTimes,
	getFilteredSwitchTasks,
	getFilteredTimeRegs,
} from '../timesheet-table-util/DataUtil';
import {TIME_PERIOD} from '../../../timesheets_change_date_view';
import {buildWeekArr, COLUMN_NAME} from '../../time_row_utils';
import {getSocketConfig} from '../../../timesheet_socket_handling';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../../../../containers/event_manager';
import {constructSearchQuery} from '../../deprecated/timesheets_data_fetch_util';
import Util from '../../../../../shared/util/util';

const TimesheetTableWrapper = ({
	viewer,
	relay,
	retry,
	setSocketVariables,
	setSocketConfig,
	selectedPerson,
	selectedSwitch,
	enabledColumns,
	timeRegistrations,
	timeRegsForPeriod,
	weekStart,
	weekEnd,
	currentViewingDate,
	timePeriod,
	searchValue,
	selectDayFromCalendar,
	holidays,
	lockedDate,
	isFullyLocked,
	usingTimeApproval,
	usingInternalTimeApproval,
	usingTimeOffApproval,
	periodTimeApprovalStatus,
	overBudgetProgramsByCompanyProjectId,
	timeRegistrationTargetSuggestionsData,
}) => {
	const selectedSwitchLocalStorageKey = 'timesheets-selected-switch-v2';

	const history = useHistory();
	const intl = useIntl();
	const [loading, setLoading] = useState(false);

	const didMount = useRef(false);
	const doneFetching = useRef(true);
	const refetchVariables = useRef(null);

	const refetch = (variables, callback, options) => {
		relay.refetch(variables, null, callback, options);
		refetchVariables.current = variables;
		setSocketVariables(variables);
	};

	const refetchTable = () => {
		if (refetchVariables.current) {
			relay.refetch(refetchVariables.current);
		} else {
			retry();
		}
	};

	const [sortValue, setSortValue] = useState(
		localStorage.getItem('time-page-sort') ? localStorage.getItem('time-page-sort') : COLUMN_NAME.PROJECT_INDICATOR
	);
	const [sortAscending, setSortAscending] = useState(localStorage.getItem('time-page-sort-ascending') || true);

	const [movedTasks, setMovedTasks] = useState([]);
	const [movedIdleTimes, setMovedIdleTimes] = useState([]);

	const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;
	const weekDays = isWeekView ? buildWeekArr(currentViewingDate.clone().endOf('week'), 7) : [];

	useEffect(() => {
		const selectedPersonIdInt = parseInt(atob(selectedPerson.id).replace('Person:', ''));
		setSocketConfig(getSocketConfig(selectedPersonIdInt), relay.refetch);
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchTable);

		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchTable);
		};
	}, []);

	useEffect(() => {
		// Only run if not mount
		if (!didMount.current) {
			didMount.current = true;
			return;
		}
		const onSuccess = () => {
			setLoading(false);
			doneFetching.current = true;
		};

		setLoading(true);
		doneFetching.current = false;

		const searchQuery = constructSearchQuery(selectedSwitch, currentViewingDate, isWeekView);

		// Suggested time is queried in a completely different way, and will therefore need to be handled separately
		if (selectedSwitch === 'suggested') {
			Util.localStorageSetItem(selectedSwitchLocalStorageKey, selectedSwitch);

			refetch(
				{
					taskTypeSearchQuery: searchQuery,
					fetchSwitchTasks: false,
					fetchSuggestedTasks: true,
				},
				onSuccess,
				{force: true}
			);
			return;
		} else if (selectedSwitch && selectedSwitch !== 'none') {
			Util.localStorageSetItem(selectedSwitchLocalStorageKey, selectedSwitch);
		}

		// No switches selected
		if (searchQuery.filters.length === 0) {
			Util.localStorageSetItem(selectedSwitchLocalStorageKey, 'none');

			refetch(
				{
					taskTypeSearchQuery: searchQuery,
					fetchSwitchTasks: false,
					fetchSuggestedTasks: false,
				},
				onSuccess,
				{force: true}
			);
		} else {
			refetch(
				{
					taskTypeSearchQuery: searchQuery,
					fetchSwitchTasks: true,
					fetchSuggestedTasks: false,
				},
				onSuccess,
				{force: true}
			);
		}
	}, [selectedSwitch]);

	const _suggestedSwitchTasks = getSuggestedSwitchTasks(viewer);
	const _switchTasks = getSwitchTasks(viewer);
	const _switchIdleTimes = getSwitchIdleTimes(viewer, selectedSwitch);

	useEffect(() => {
		if (
			doneFetching.current &&
			(_suggestedSwitchTasks.length !== 0 || _switchTasks.length !== 0 || _switchIdleTimes.length !== 0)
		) {
			dispatch(EVENT_ID.SWITCH_EMPTY, '');
		} else if (doneFetching.current) {
			dispatch(EVENT_ID.SWITCH_EMPTY, selectedSwitch ? selectedSwitch : '');
		}
	}, [doneFetching.current]);

	const _filteredTimeRegs = getFilteredTimeRegs.call(this, timeRegsForPeriod, movedTasks, movedIdleTimes, searchValue);

	const _timeRegsMoved = getTimeRegsMoved(timeRegsForPeriod, movedTasks, movedIdleTimes);
	const _groupedMovedTasks = getGroupedMovedTasks(isWeekView, _timeRegsMoved);

	// Filter out switch tasks that are already in time regs
	const _filteredSwitchTasks = getFilteredSwitchTasks.call(
		this,
		selectedSwitch,
		_suggestedSwitchTasks,
		selectedPerson,
		timeRegsForPeriod,
		searchValue,
		_switchTasks
	);

	const _filteredSwitchIdleTimes = getFilteredSwitchIdleTimes.call(
		this,
		selectedSwitch,
		_suggestedSwitchTasks,
		timeRegsForPeriod,
		searchValue,
		_switchIdleTimes
	);

	const combinedSwitchList = getCombinedSwitchList(
		isWeekView,
		_groupedMovedTasks,
		_filteredSwitchTasks,
		_filteredSwitchIdleTimes,
		_timeRegsMoved
	);

	const topSection = getTopSection(isFullyLocked, combinedSwitchList);

	const _getDayRegsForSwitchEntity = entity => {
		return getDayRegsForSwitchEntity(isWeekView, _groupedMovedTasks, entity);
	};

	const entityList = getEntityList(
		[],
		_filteredTimeRegs,
		sortValue,
		sortAscending,
		isWeekView,
		weekDays,
		topSection,
		_getDayRegsForSwitchEntity
	);

	return (
		<TimesheetTable
			entityList={entityList}
			setExternalSortValue={setSortValue}
			setExternalSortAscending={setSortAscending}
			setExternalMovedTasks={setMovedTasks}
			setExternalMovedIdleTimes={setMovedIdleTimes}
			loading={loading}
			intl={intl}
			history={history}
			viewer={viewer}
			relay={relay}
			retry={retry}
			selectedPerson={selectedPerson}
			selectedSwitch={selectedSwitch}
			enabledColumns={enabledColumns}
			timeRegistrations={timeRegistrations}
			timeRegsForPeriod={timeRegsForPeriod}
			weekStart={weekStart}
			weekEnd={weekEnd}
			currentViewingDate={currentViewingDate}
			timePeriod={timePeriod}
			searchActive={!!searchValue}
			filterActive={false}
			selectDayFromCalendar={selectDayFromCalendar}
			holidays={holidays}
			lockedDate={lockedDate}
			isFullyLocked={isFullyLocked}
			usingTimeApproval={usingTimeApproval}
			usingInternalTimeApproval={usingInternalTimeApproval}
			usingTimeOffApproval={usingTimeOffApproval}
			periodTimeApprovalStatus={periodTimeApprovalStatus}
			overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
			timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData}
		/>
	);
};

const TimesheetTableWrapperQuery = graphql`
	query TimesheetTableWrapper_Query(
		$taskTypeSearchQuery: TaskSearchQueryType
		$fetchSwitchTasks: Boolean!
		$sortValue: String
		$fetchSuggestedTasks: Boolean!
		$timePeriod: String
		$currentViewingDate: String
		$personId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "TimesheetTableWrapper")
			...TimesheetTableWrapper_viewer
				@arguments(
					taskTypeSearchQuery: $taskTypeSearchQuery
					fetchSwitchTasks: $fetchSwitchTasks
					sortValue: $sortValue
					fetchSuggestedTasks: $fetchSuggestedTasks
					timePeriod: $timePeriod
					currentViewingDate: $currentViewingDate
					personId: $personId
				)
		}
	}
`;

export {TimesheetTableWrapperQuery};

export default withSocketHandling(
	createRefetchContainer(
		TimesheetTableWrapper,
		{
			viewer: graphql`
				fragment TimesheetTableWrapper_viewer on Viewer
				@argumentDefinitions(
					taskTypeSearchQuery: {type: "TaskSearchQueryType"}
					fetchSwitchTasks: {type: "Boolean!"}
					sortValue: {type: "String"}
					fetchSuggestedTasks: {type: "Boolean!"}
					timePeriod: {type: "String"}
					currentViewingDate: {type: "String"}
					personId: {type: "ID!"}
				) {
					id
					actualPersonId
					harvestUser
					unit4User
					company {
						id
						bambooHREnabled
						harvestEnabled
						unit4Enabled
						useTimeOffApproval
						roles(includeDisabled: false) {
							edges {
								node {
									id
									name
								}
							}
						}
						taskTypeQueryTasks: allTasks(
							first: 1000
							searchQuery: $taskTypeSearchQuery
							sortValue: $sortValue
							approvedOnly: true
							runningProjectOnly: true
						) @connection(key: "Company_taskTypeQueryTasks", filters: []) @include(if: $fetchSwitchTasks) {
							pageInfo {
								endCursor
								hasNextPage
							}
							edges {
								node {
									id
									userCanDeleteTask
									userCantDeleteTaskReason
									name
									latestUiUpdateAt
									startDay
									startMonth
									startYear
									deadlineYear
									deadlineMonth
									deadlineDay
									timeLeft
									estimateForecastMinutes
									totalMinutesRegistered
									companyTaskId
									favoured
									highPriority
									bug
									blocked
									approved
									canStart
									canBeSetToDone
									billable
									fullAccessToProject
									parentTaskId
									taskType
									statusColumnV2 {
										id
										name
										category
									}
									assignedPersons {
										id
										firstName
										lastName
										email
										profilePictureId
										profilePictureDefaultId
										permissions
									}
									project {
										id
										name
										companyProjectId
										customProjectId
										minutesPerEstimationPoint
										estimationUnit
										projectColor
										projectStartYear
										projectStartMonth
										projectStartDay
										projectEndYear
										projectEndMonth
										projectEndDay
										manualProgressOnProjectEnabled
										manualProgressOnPhasesEnabled
										manualProgressOnTasksEnabled
										taskLevels
										status
										fullAccessToProject
										projectPerson(personId: $personId) {
											id
											role {
												id
												name
											}
										}
										harvestProject {
											id
										}
										unit4Project {
											id
											activitiesEnabled
										}
										client {
											id
											name
										}
									}
									phase {
										id
										name
									}
								}
								cursor
							}
						}
						taskTypeQueryIdleTimes: idleTimes(first: 100000, favouredOnly: true)
							@include(if: $fetchSwitchTasks)
							@connection(key: "Company_taskTypeQueryIdleTimes", filters: []) {
							edges {
								node {
									id
									name
									isInternalTime
									favoured
									disabled
								}
							}
						}
					}
					timeRegistrationSuggestedTasks(timePeriod: $timePeriod, currentViewingDate: $currentViewingDate)
						@include(if: $fetchSuggestedTasks) {
						id
						name
						task {
							id
							name
							progress
							companyTaskId
							highPriority
							approved
							favoured
							fullAccessToProject
							latestUiUpdateAt
							startDay
							startMonth
							startYear
							deadlineYear
							deadlineMonth
							deadlineDay
							timeLeft
							estimateForecastMinutes
							totalMinutesRegistered
							parentTaskId
							taskType
							billable
							assignedPersons {
								id
							}
							statusColumnV2 {
								category
							}
							project {
								id
								name
								status
								companyProjectId
								customProjectId
								projectColor
								estimationUnit
								fullAccessToProject
								manualProgressOnProjectEnabled
								manualProgressOnPhasesEnabled
								manualProgressOnTasksEnabled
								client {
									id
									name
								}
								harvestProject {
									id
									name
								}
								unit4Project {
									id
									name
								}
							}
							phase {
								id
								name
							}
						}
						idleTime {
							id
							name
							isInternalTime
							favoured
						}
						total
						reasons {
							id
							label
							value
							sentenceId
						}
					}
					taskSuggestionsRecommender(timePeriod: $timePeriod, currentViewingDate: $currentViewingDate)
						@include(if: $fetchSuggestedTasks) {
						recommendationInstanceId
						recommendationId
						generation
						timeStep
					}
				}
			`,
		},
		graphql`
			query TimesheetTableWrapperRefetchQuery(
				$taskTypeSearchQuery: TaskSearchQueryType
				$fetchSwitchTasks: Boolean!
				$sortValue: String
				$fetchSuggestedTasks: Boolean!
				$timePeriod: String
				$currentViewingDate: String
				$personId: ID!
			) {
				viewer {
					component(name: "TimesheetTableWrapper")
					...TimesheetTableWrapper_viewer
						@arguments(
							taskTypeSearchQuery: $taskTypeSearchQuery
							fetchSwitchTasks: $fetchSwitchTasks
							sortValue: $sortValue
							fetchSuggestedTasks: $fetchSuggestedTasks
							timePeriod: $timePeriod
							currentViewingDate: $currentViewingDate
							personId: $personId
						)
				}
			}
		`
	)
);
