import React, { useState } from 'react';
import { Checkbox, DeprecatedCreativeModal as Modal, FlexRow, Label, Text, TextField } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
const SaveReportModal = ({ saveReport, report, closeModal }) => {
    const intl = useIntl();
    const [privateAccess, setPrivateAccess] = useState(true);
    const [name, setName] = useState(report.name);
    const togglePrivateAccess = () => {
        setPrivateAccess(!privateAccess);
    };
    return (React.createElement(Modal, { title: intl.formatMessage({ id: 'saved_report.save_report' }), onCreateHandler: () => saveReport(Object.assign(Object.assign({}, report), { name, privateAccess }), closeModal), creativeButtonText: intl.formatMessage({ id: 'common.save' }), size: "m", closeModal: closeModal, replicateDesignSystem: true },
        React.createElement(TextField, { label: "Report Name", value: name, onValueChange: setName }),
        React.createElement(Label, null, intl.formatMessage({ id: 'report.share-company-label' })),
        React.createElement(FlexRow, null,
            React.createElement(Checkbox, { checked: !privateAccess, onChange: togglePrivateAccess }),
            React.createElement(Text, null, intl.formatMessage({ id: 'report.private_access.description' })))));
};
export default SaveReportModal;
