import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import UpdateLabelMutation from '../../../mutations/update_label_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {InputLabel, ModalHeaderInput} from 'web-components';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../forecast-app/shared/util/util';
import {Field, ModalBody} from '../../shared/components/modals/Modal.styled';
import ColorsPicker from '../../../components/colors_picker';
import LabelEntityWarning from './label_entity_warning';

const UpdateLabelModal = ({labelId, company, closeModal}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const NO_CATEGORY = formatMessage({id: 'settings_labels.no_category'});
	const label = company.label;
	if (!label) {
		return null;
	}

	const existingNames = new Set(
		company.labels.edges
			.map(edge => edge.node)
			.filter(label => label.id !== labelId)
			.map(label => label.name)
	);
	const [errorMessage, setErrorMessage] = useState();
	const [name, setName] = useState(label.name);
	const [color, setColor] = useState(label.color);
	const [deletionWarnings, setDeletionWarnings] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(label.category?.id);

	const categories = company.labelCategories.edges.map(label => label.node);
	const categoryOptions = categories.map(label => ({value: label.id, label: label.name}));
	categoryOptions.push({value: null, label: NO_CATEGORY});

	const onNameChange = newName => {
		setName(newName);
		const duplicateName = newName !== label.name && existingNames.has(newName);
		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings_labels.already_exists'}));
		} else {
			setErrorMessage(null);
		}
	};

	const update = () => {
		Util.CommitMutation(
			UpdateLabelMutation,
			{
				id: labelId,
				name: name !== label.name ? name : undefined,
				color: color !== label.color ? color : undefined,
				categoryId: selectedCategory,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_labels.changes_saved'}),
				});
			}
		);
	};

	const onSelectCategory = option => {
		const categoryId = option.value;
		setSelectedCategory(categoryId);
		const warnings = [];
		const category = categories.find(category => category.id === categoryId);

		if (category) {
			if (!category.allowOnPeople && label.peopleCount > 0) {
				warnings.push(formatMessage({id: 'settings_labels.not_allowed_people'}));
			}
			if (!category.allowOnTasks && label.taskCount > 0) {
				warnings.push(formatMessage({id: 'settings_labels.not_allowed_tasks'}));
			}
			if (!category.allowOnProjects && label.projectCount > 0) {
				warnings.push(formatMessage({id: 'settings_labels.not_allowed_projects'}));
			}
		}
		setDeletionWarnings(warnings);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: update,
					disabled: !name || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<div>
					<ModalHeaderInput
						cy="label-name"
						placeholder={formatMessage({id: 'common.name_placeholder'})}
						value={name}
						onChange={onNameChange}
						hasError={!name || !!errorMessage}
						errorMessage={errorMessage}
					/>
					<ModalBody>
						<Field>
							<InputLabel
								text={formatMessage({id: 'common.color'})}
								child={<ColorsPicker value={color} onColorSelect={setColor} size={20} usePortal />}
							/>
						</Field>
						<Field>
							<DropdownV2
								options={categoryOptions}
								onChange={onSelectCategory}
								value={selectedCategory}
								label={formatMessage({id: 'settings_labels.category'})}
								placeholder={NO_CATEGORY}
								inputName={'category-input'}
								inputCy={'category'}
								listDataCy={'category'}
							/>
						</Field>
						<Field>
							{deletionWarnings.map(deletionWarning => (
								<LabelEntityWarning
									updated
									itemName={formatMessage(
										{id: 'settings_labels.warning_deletion'},
										{entity: deletionWarning}
									)}
									itemWarning={formatMessage(
										{id: 'settings_labels.warning_deletion_details'},
										{name: label.name, entity: deletionWarning}
									)}
									key={'warning-' + deletionWarning}
									cy={`warning-${deletionWarning}`}
								/>
							))}
						</Field>
					</ModalBody>
				</div>
			}
		/>
	);
};

const updateLabelModalQuery = graphql`
	query UpdateLabelModal_Query($labelId: ID) {
		viewer {
			actualPersonId
			component(name: "update_label_modal")
			company {
				...UpdateLabelModal_company @arguments(labelId: $labelId)
			}
		}
	}
`;

export {updateLabelModalQuery};

export default createFragmentContainer(UpdateLabelModal, {
	company: graphql`
		fragment UpdateLabelModal_company on Company @argumentDefinitions(labelId: {type: "ID!"}) {
			id
			createdAt
			labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
				edges {
					node {
						id
						name
						allowOnTasks
						allowOnProjects
						allowOnPeople
					}
				}
			}
			label(id: $labelId) {
				id
				name
				color
				taskCount
				projectCount
				peopleCount
				category {
					id
					name
					allowOnTasks
					allowOnProjects
					allowOnPeople
				}
			}
			labels(first: 1000) @connection(key: "Company_labels") {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`,
});
