import React from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {ButtonDropdown, DropdownHandler, FlexRow, Icon, ShortcutSignifier} from '@forecast-it/design-system';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {hasPermission, isClientUser} from '../../shared/util/PermissionsUtil';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {HIDDEN_FEATURES, MODULE_TYPES} from '../../../constants';
import Util from '../../shared/util/util';
import {hasModule} from '../../shared/util/ModuleUtil';
import CreateTaskMutation from '../../../mutations/create_task_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import ProgramUtil from '../../shared/util/ProgramUtil';
import CompanySetupUtil from '../../shared/util/CompanySetupUtil';
import {createReport} from '../../reports-tab/reports-page/actions/create-report/CreateReport';

export const DropDownButton = formatMessage => (
	<ButtonDropdown emphasis={'medium'}>{formatMessage({id: 'common.create'})}</ButtonDropdown>
);

const CreateButton = props => {
	const history = useHistory();
	const {formatMessage} = useIntl();

	const handleCreateProject = () => {
		trackEvent('New Button - New Project', 'Clicked');
		showModal({
			type: MODAL_TYPE.NEW_PROJECT_V2,
		});
	};

	const handleCreateTeamMember = () => {
		trackEvent('New Button - New Team Member', 'Clicked');
		showModal({
			type: MODAL_TYPE.ADD_TEAM_MEMBER,
			addClientUser: false,
			companyId: props.viewer.company.id,
			buttonLocation: 'Header',
		});
	};

	const handleCreateClient = () => {
		trackEvent('New Button - New Client', 'Clicked');
		showModal({
			type: MODAL_TYPE.ADD_CLIENT,
		});
	};

	const handleCreateInsight = () => {
		trackEvent('New Button - New Report', 'Clicked');
		if (hasFeatureFlag('reports_redesign')) {
			createReport();
		} else {
			props.history.push('/reports/new/step1');
		}
	};

	const handleCreateExpenseItem = () => {
		trackEvent('New Button - New Expense Item', 'Clicked');
		showModal({
			type: MODAL_TYPE.EXPENSE_ITEM,
			projectId: props.viewer.project ? props.viewer.project.id : null,
		});
	};

	const handleCreateTask = () => {
		trackEvent('New Button - New Task', 'Clicked');

		const onSuccess = result => {
			if (result.createTask.errors && result.createTask.errors.length === 1) {
				Util.checkForSageErrorAndShowModal(result.createTask.errors);
			} else {
				Util.showTaskModal(result.createTask.task.node.companyTaskId, history);
			}
		};

		if (props.viewer.project && props.viewer.project.status !== 'HALTED' && props.viewer.project.status !== 'DONE') {
			Util.CommitSchedulingModalUpdate(
				CreateTaskMutation,
				{
					projectId: props.viewer.project.id,
					name: 'New task',
					companyId: props.viewer.company.id,
					placementOfTask: 'TOP',
				},
				onSuccess
			);
		} else {
			showModal({
				type: MODAL_TYPE.TASK_LOCATION,
				viewerId: props.viewer.id,
				currentProjectGroupId: null,
				currentProjectGroupName: null,
			});
		}
	};

	const handleCreateProjectGroup = () => {
		trackEvent('New Button - New Project Group', 'Clicked');
		showModal({
			type: MODAL_TYPE.ADD_PROJECT_GROUP,
		});
	};

	const handleCreateTimeEntry = () => {
		trackEvent('New Button - New Time Entry', 'Clicked');
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
				personId: props.viewer.actualPersonId,
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder: false,
				timerProject: null,
				timerTask: null,
				useTimer: false,
				personId: props.viewer.actualPersonId,
			});
		}
	};

	const handleCreateTimeOff = () => {
		trackEvent('New Button - New Time Off', 'Clicked');
		showModal({
			type: MODAL_TYPE.TIMER_V3,
			isPreviousTimerReminder: false,
			timerProject: null,
			timerTask: null,
			useTimer: false,
			timeOffOnly: true,
			personId: props.viewer.actualPersonId,
		});
	};

	const handleCreateProgram = () => {
		trackEvent('New Button - New Program', 'Clicked');
		showModal({
			type: MODAL_TYPE.CREATE_PROGRAM,
		});
	};

	return (
		<DropdownHandler>
			<DropdownHandler.Trigger
				onClick={() => trackEvent('Navigation Bar Create Menu Button', 'Clicked')}
				data-cy={'create-new-button'}
			>
				{DropDownButton(formatMessage)}
			</DropdownHandler.Trigger>
			<DropdownHandler.Content offset={6}>
				<DropdownHandler.Item onClick={handleCreateTask} data-cy={'create-new-task'}>
					<Icon icon="task" size="m" />
					{formatMessage({id: 'common.task'})}
				</DropdownHandler.Item>
				{hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) && (
					<DropdownHandler.Item onClick={handleCreateProject} data-cy={'create-new-project'}>
						<Icon icon="project" size="m" />
						{formatMessage({id: 'common.project'})}
					</DropdownHandler.Item>
				)}
				{hasModule(MODULE_TYPES.PROGRAMS) && hasPermission(PERMISSION_TYPE.PROGRAMS_CREATE) && (
					<DropdownHandler.Item onClick={handleCreateProgram} data-cy={'create-new-program'}>
						<Icon icon="program" size="m" />
						{ProgramUtil.programText(formatMessage)}
					</DropdownHandler.Item>
				)}
				{hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) && (
					<DropdownHandler.Item onClick={handleCreateProjectGroup} data-cy={'create-new-connected-project'}>
						<Icon icon="connectedProject" size="m" />
						{Util.titleCase(formatMessage({id: 'common.connected_project'}))}
					</DropdownHandler.Item>
				)}
				{isClientUser() || Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) ? null : (
					<>
						<DropdownHandler.Separator />
						<DropdownHandler.Item onClick={handleCreateTimeEntry} data-cy={'create-new-time-entry'}>
							<Icon icon="timeEntry" size="m" />
							{Util.titleCase(formatMessage({id: 'common.time_entry'}))}
							<FlexRow justifyContent={'flex-end'}>
								<ShortcutSignifier name={'='} />
							</FlexRow>
						</DropdownHandler.Item>
					</>
				)}
				{isClientUser() ||
				Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) ||
				!hasFeatureFlag('new_time_registration_modal') ||
				props.viewer.company.bambooHREnabled ? null : (
					<DropdownHandler.Item onClick={handleCreateTimeOff} data-cy={'create-new-time-off'}>
						<Icon icon="timeOff" size="m" />
						Time Off
					</DropdownHandler.Item>
				)}
				{!isClientUser() && CompanySetupUtil.hasCreateExpense() && (
					<>
						<DropdownHandler.Item onClick={handleCreateExpenseItem} data-cy={'create-new-expense'}>
							<Icon icon="expense" size="m" />
							{formatMessage({id: 'header.expense-item'})}
						</DropdownHandler.Item>
						<DropdownHandler.Separator />
					</>
				)}
				{hasPermission(PERMISSION_TYPE.CLIENT_CREATE) &&
					!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) &&
					!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
						<DropdownHandler.Item onClick={handleCreateClient} data-cy={'create-new-client'}>
							<Icon icon="client" size="m" />
							{formatMessage({id: 'common.client'})}
						</DropdownHandler.Item>
					)}
				{/* <DropdownHandler.Item onClick={onClick}>
					<Icon icon="grid" size="m" />
					Invoice
				</DropdownHandler.Item> */}
				{hasPermission(PERMISSION_TYPE.INSIGHTS_CREATE) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
					<DropdownHandler.Item onClick={handleCreateInsight} data-cy={'create-new-report'}>
						<Icon icon="report" size="m" />
						{formatMessage({id: 'common.report'})}
					</DropdownHandler.Item>
				)}
				{hasPermission(PERMISSION_TYPE.PERSON_CREATE) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
					<DropdownHandler.Item onClick={handleCreateTeamMember} cy={'create-new-team-member'}>
						<Icon icon="admin" size="m" />
						{formatMessage({id: 'common.team_member'})}
					</DropdownHandler.Item>
				)}
			</DropdownHandler.Content>
		</DropdownHandler>
	);
};
const CreateButtonQuery = graphql`
	query CreateButton_Query($projectId: String) {
		viewer {
			actualPersonId
			component(name: "top_navigation_create_button")
			...CreateButton_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {CreateButtonQuery};

export default withRouter(
	createFragmentContainer(CreateButton, {
		viewer: graphql`
			fragment CreateButton_viewer on Viewer @argumentDefinitions(projectId: {type: "String"}) {
				id
				actualPersonId
				availableFeatureFlags {
					# Fetching this field despite it not being used to force the CreateButton component to rerender when feature flags are updated, as the list otherwise does not respect feature flag updates
					key
				}
				company {
					id
					bambooHREnabled
				}
				project(id: $projectId) {
					id
					status
				}
			}
		`,
	})
);
