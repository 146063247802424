/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyTaskId",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullAccessToProject",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "pageSize",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "taskTypeSearchQuery",
                "type": "TaskSearchQueryType"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "sortValue",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "timePeriod",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "currentViewingDate",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "personId",
                "type": "ID!"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "pageSize",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": [
                        "company",
                        "taskTypeQueryTasks"
                    ]
                }
            ]
        },
        "name": "TimesheetThisWeeksWorkTableWrapper_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actualPersonId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "harvestUser",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit4User",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bambooHREnabled",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "harvestEnabled",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit4Enabled",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useTimeOffApproval",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "includeDisabled",
                                "value": false
                            }
                        ],
                        "concreteType": "RoleTypeConnection",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "RoleTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": "roles(includeDisabled:false)"
                    },
                    {
                        "alias": "taskTypeQueryTasks",
                        "args": null,
                        "concreteType": "TaskTypeConnection",
                        "kind": "LinkedField",
                        "name": "__Company_taskTypeQueryTasks_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "endCursor",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hasNextPage",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TaskTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Task",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "userCanDeleteTask",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "userCantDeleteTaskReason",
                                                "storageKey": null
                                            },
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "latestUiUpdateAt",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "timeLeft",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "estimateForecastMinutes",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "totalMinutesRegistered",
                                                "storageKey": null
                                            },
                                            (v3 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "favoured",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "highPriority",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "bug",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "blocked",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "approved",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "canStart",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "canBeSetToDone",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "billable",
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "parentTaskId",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Task",
                                                "kind": "LinkedField",
                                                "name": "parentTask",
                                                "plural": false,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    (v3 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "taskType",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "StatusColumnV2",
                                                "kind": "LinkedField",
                                                "name": "statusColumnV2",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "category",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Person",
                                                "kind": "LinkedField",
                                                "name": "assignedPersons",
                                                "plural": true,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "firstName",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "lastName",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "email",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "profilePictureId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "profilePictureDefaultId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "permissions",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProjectType",
                                                "kind": "LinkedField",
                                                "name": "project",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "companyProjectId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "minutesPerEstimationPoint",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "estimationUnit",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectColor",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectStartYear",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectStartMonth",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectStartDay",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectEndYear",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectEndMonth",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectEndDay",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "taskLevels",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "status",
                                                        "storageKey": null
                                                    },
                                                    (v4 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": [
                                                            {
                                                                "kind": "Variable",
                                                                "name": "personId",
                                                                "variableName": "personId"
                                                            }
                                                        ],
                                                        "concreteType": "ProjectPerson",
                                                        "kind": "LinkedField",
                                                        "name": "projectPerson",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Role",
                                                                "kind": "LinkedField",
                                                                "name": "role",
                                                                "plural": false,
                                                                "selections": (v2 /*: any*/),
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "HarvestProjectType",
                                                        "kind": "LinkedField",
                                                        "name": "harvestProject",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Unit4ProjectType",
                                                        "kind": "LinkedField",
                                                        "name": "unit4Project",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "activitiesEnabled",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Client",
                                                        "kind": "LinkedField",
                                                        "name": "client",
                                                        "plural": false,
                                                        "selections": (v2 /*: any*/),
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "customProjectId",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PhaseType",
                                                "kind": "LinkedField",
                                                "name": "phase",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "__typename",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "cursor",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '0bf2891e23ed22ebd88fcd914595c7a1';
export default node;
