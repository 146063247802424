import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import outlookLogo from '../../../images/integrations/calendar/outlook.png';
import appleLogo from '../../../images/integrations/calendar/apple.png';
import googleLogo from '../../../images/integrations/calendar/google.png';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../shared/util/util';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {BetaTag} from '@forecast-it/design-system';

class appCalendar extends React.Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('App Catalog - Calendar');
	}

	componentDidMount() {
		document.title = 'Calendar - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-calendar');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleCalenderButtonClick() {
		if (!this.props.viewer.company.calendarEnabled) {
			tracking.trackEvent('Calendar integration enabled');
			trackEvent('Calendar Integration', 'Enabled');
		}
		Util.CommitMutation(UpdateCompanyMutation, {
			id: this.props.viewer.company.id,
			calendarEnabled: !this.props.viewer.company.calendarEnabled,
		});
	}

	handleCalenderApiButtonClick() {
		if (!this.props.viewer.company.calendarApiEnabled) {
			trackEvent('Calendar API Integration', 'Enabled');
		}
		Util.CommitMutation(UpdateCompanyMutation, {
			id: this.props.viewer.company.id,
			calendarApiEnabled: !this.props.viewer.company.calendarApiEnabled,
		});
	}

	openGoogleHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5563214440465');
	}

	openAppleHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5091073777169');
	}

	openOutlookHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5091285483793');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<div className="calendar-logos-wrapper">
										<img width={42} height={42} alt="apple logo" src={appleLogo} />
										<div>
											<img width={42} height={42} alt="outlook logo" src={outlookLogo} />
											<img width={42} height={42} alt="gcalendar logo" src={googleLogo} />
										</div>
									</div>
									<div className="main-body">
										<div className="title">Sync Task deadlines to users's calendar</div>
										<div className="title-description-wrapper">
											<div className="description">
												<FormattedHTMLMessage id="settings.app_catalog.calendar.description" />
											</div>
										</div>
									</div>
								</div>
								<div className="input-fields">
									<button className="submit-button" onClick={this.handleCalenderButtonClick.bind(this)}>
										{this.props.viewer.company.calendarEnabled
											? formatMessage({id: 'settings.app_catalog.calendar.deactivate_button'})
											: formatMessage({id: 'settings.app_catalog.calendar.activate_button'})}
									</button>
									<button className="help-button" onClick={this.openGoogleHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_google_calendar" />
									</button>
									<button className="help-button" onClick={this.openAppleHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_apple_mail" />
									</button>
									<button className="help-button" onClick={this.openOutlookHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_outlook" />
									</button>
								</div>
							</fieldset>
						</div>
						{hasFeatureFlag('google_calendar_integration') ? (
							<div className="inner">
								<fieldset className="general">
									<div className="header-wrapper">
										<div className="calendar-logos-wrapper">
											<img width={66} height={66} alt="gcal logo" src={googleLogo} />
										</div>
										<div className="main-body">
											<div style={{display: 'flex', alignItems: 'baseline'}}>
												<div className="title">View your calendar events inside Forecast</div>
												<div style={{paddingLeft: '8px'}}>
													<BetaTag />
												</div>
											</div>
											<div className="title-description-wrapper">
												<div className="description">
													<FormattedHTMLMessage id="settings.app_catalog.calendar_api.description" />
												</div>
											</div>
										</div>
									</div>
									<div className="input-fields">
										<button
											className="submit-button"
											onClick={this.handleCalenderApiButtonClick.bind(this)}
										>
											{this.props.viewer.company.calendarApiEnabled
												? formatMessage({id: 'settings.app_catalog.calendar.deactivate_button'})
												: formatMessage({id: 'settings.app_catalog.calendar.activate_button'})}
										</button>
										<button className="help-button" onClick={this.openGoogleHelpCenter.bind(this)}>
											<FormattedMessage id="settings.app_catalog.help_google_calendar" />
										</button>
									</div>
								</fieldset>
							</div>
						) : null}
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appCalendarQuery = graphql`
	query appCalendar_Query {
		viewer {
			actualPersonId
			component(name: "app_calendar")
			...appCalendar_viewer
		}
	}
`;

export {appCalendarQuery};

export default createFragmentContainer(injectIntl(withRouter(appCalendar)), {
	viewer: graphql`
		fragment appCalendar_viewer on Viewer {
			id
			company {
				id
				calendarEnabled
				calendarApiEnabled
			}
		}
	`,
});
