import {HIDDEN_FEATURES, MODULE_TYPES, TIERS} from '../../../constants';
import {hasModule} from './ModuleUtil';
import {
	sageCostRestricted,
	sageFinancialsRestricted,
	sageRevenueRecognitionRestricted,
	sageCreateExpenseRestricted,
	sageCreateInvoiceRestricted,
	sageReportingRestricted,
} from './sage/SageRestrictionUtil';

const CACHE = new Map();
const COMPANY_SETUP_STORAGE_KEY = 'companySetup';

const DEFAULT_SETUP = {
	revenueEnabled: true,
	timesheetsEnabled: true,
	clientsEnabled: true,
	tier: TIERS.LITE,
	weekendDisplayPerUser: true,
	weekendDisplayShowAlways: null,
};

export const COMPANY_SETUP = {
	TIME_SHEETS_ENABLED: 'timesheetsEnabled',
	REVENUE_ENABLED: 'revenueEnabled',
	CLIENTS_ENABLED: 'clientsEnabled',
	TIER: 'tier',
	WEEKEND_DISPLAY_PER_USER: 'weekendDisplayPerUser',
	WEEKEND_DISPLAY_SHOW_ALWAYS: 'weekendDisplayShowAlways',
};

export const setCompanySetup = company => {
	if (company) {
		CACHE.clear();

		const companySetup = {
			revenueEnabled: company.revenueEnabled,
			timesheetsEnabled: company.timesheetsEnabled,
			clientsEnabled: company.clientsEnabled,
			tier: company.tier,
			weekendDisplayPerUser: company.weekendDisplayPerUser,
			weekendDisplayShowAlways: company.weekendDisplayShowAlways,
		};

		sessionStorage.setItem(COMPANY_SETUP_STORAGE_KEY, JSON.stringify(companySetup));

		// populate cache
		for (const [key, value] of Object.entries(companySetup)) {
			CACHE.set(key, value);
		}
	}
};

const setCompanySetupCache = () => {
	if (CACHE.size === 0) {
		const sessionCompanySetup = sessionStorage.getItem(COMPANY_SETUP_STORAGE_KEY);
		const companySetup = sessionCompanySetup ? JSON.parse(sessionCompanySetup) : DEFAULT_SETUP;
		setCompanySetup(companySetup);
	}
};

export const getCompanySetting = setting => {
	setCompanySetupCache();
	return CACHE.get(setting);
};

export const setCompanySetting = (setting, value) => {
	if (COMPANY_SETUP[setting]) {
		setCompanySetupCache();
		return CACHE.set(setting, value);
	}

	return null;
};

export const isFeatureHidden = featureName => {
	switch (featureName) {
		case HIDDEN_FEATURES.TIME_REGISTRATIONS:
			return getCompanySetting(COMPANY_SETUP.TIME_SHEETS_ENABLED) === false;
		case HIDDEN_FEATURES.BUDGET_TYPES:
			return getCompanySetting(COMPANY_SETUP.REVENUE_ENABLED) === false;
		case HIDDEN_FEATURES.REVENUE:
			return (
				getCompanySetting(COMPANY_SETUP.REVENUE_ENABLED) === false &&
				!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_REVENUE_ACCESS)
			);
		case HIDDEN_FEATURES.CLIENT:
			return getCompanySetting(COMPANY_SETUP.CLIENTS_ENABLED) === false;
		case HIDDEN_FEATURES.COST:
			return sageCostRestricted();
		case HIDDEN_FEATURES.REVENUE_RECOGNITION:
			return sageRevenueRecognitionRestricted();
		case HIDDEN_FEATURES.CREATE_EXPENSE:
			return sageCreateExpenseRestricted();
		case HIDDEN_FEATURES.CREATE_INVOICE:
			return sageCreateInvoiceRestricted();
		case HIDDEN_FEATURES.REPORTING:
			return sageReportingRestricted();
		default:
			return false;
	}
};

export default class CompanySetupUtil {
	static isFeatureHidden(featureName) {
		return isFeatureHidden(featureName);
	}
	static getTier() {
		return getCompanySetting(COMPANY_SETUP.TIER);
	}
	static hasCost() {
		return !isFeatureHidden(HIDDEN_FEATURES.COST);
	}
	static hasFinance() {
		return hasModule(MODULE_TYPES.FINANCE) && !sageFinancialsRestricted();
	}
	static hasFinanceLight() {
		return this.getTier() === TIERS.LITE;
	}
	static hasRevenueRecognition() {
		return this.hasFinance() && !isFeatureHidden(HIDDEN_FEATURES.REVENUE_RECOGNITION);
	}
	static hasExpenses() {
		return this.hasFinance();
	}
	static hasReporting() {
		return !isFeatureHidden(HIDDEN_FEATURES.REPORTING);
	}
	static hasCreateExpense() {
		return !isFeatureHidden(HIDDEN_FEATURES.CREATE_EXPENSE);
	}
	static hasCreateInvoice() {
		return !isFeatureHidden(HIDDEN_FEATURES.CREATE_INVOICE);
	}
	static getWeekendDisplayPerUser() {
		return getCompanySetting(COMPANY_SETUP.WEEKEND_DISPLAY_PER_USER);
	}
	static getWeekendDisplayShowAlways() {
		return getCompanySetting(COMPANY_SETUP.WEEKEND_DISPLAY_SHOW_ALWAYS);
	}
	static weekendsAreVisiblePerUser() {
		return getCompanySetting(COMPANY_SETUP.WEEKEND_DISPLAY_PER_USER) === true;
	}
	static weekendsAlwaysVisible() {
		return getCompanySetting(COMPANY_SETUP.WEEKEND_DISPLAY_SHOW_ALWAYS) === true;
	}
	static weekendsAlwaysHidden() {
		return getCompanySetting(COMPANY_SETUP.WEEKEND_DISPLAY_SHOW_ALWAYS) === false;
	}
}
