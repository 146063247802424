import React from 'react';
import styled from 'styled-components';
import {createFragmentContainer, graphql} from 'react-relay';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {NavigationBar, TopNavigation} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {hasPermission, isClientUser} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import Util from '../../shared/util/util';
import {HIDDEN_FEATURES, MODULE_TYPES, TIERS} from '../../../constants';
import {hasModule} from '../../shared/util/ModuleUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import {hasTimeApprovalAccess} from '../../../components/timesheets/TimeManagementUtil';
import ProgramUtil from '../../shared/util/ProgramUtil';
import CompanySetupUtil from '../../shared/util/CompanySetupUtil';
import {sageFinanceMenuRestricted} from '../../shared/util/sage/SageRestrictionUtil';
import Logo, {LOGO_SIZE} from '../../shared/components/logo/Logo';
import {isSagePSAWithFinancialsAccess} from '../../shared/util/sage/SageRestrictionUtil';

const NavigationLink = React.forwardRef(({LinkComponent, navigate, target, onClick, route, ...props}, ref) => {
	const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

	const handleClick = (event, navigate, target, onClick) => {
		try {
			if (onClick) onClick(event);
		} catch (ex) {
			event.preventDefault();
			throw ex;
		}

		if (
			!event.defaultPrevented && // onClick prevented default
			event.button === 0 && // ignore everything but left clicks
			(!target || target === '_self') && // let browser handle "target=_blank" etc.
			!isModifiedEvent(event) // ignore clicks with modifier keys
		) {
			event.preventDefault();
			trackEvent('Navigation Item ' + route?.trackingName, 'Clicked', {navigationItemType: 'Navigation Item'});
			// Timeout to make sure the dropdown has time to close
			setTimeout(() => {
				navigate();
			}, 50);
		}
	};

	return (
		<LinkComponent
			ref={ref}
			{...props}
			onClick={event => {
				handleClick(event, navigate, target, onClick, route);
			}}
		>
			{props.children}
		</LinkComponent>
	);
});

const filterMenuItems = arr => {
	// Filter out menuitems that have a falsy visible property. The visible property defaults to true.
	return arr.filter(item => item.visible === undefined || item.visible);
};

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 10px;
`;

const PrimaryNavigationMain = ({viewer}) => {
	const {isUsingProjectAllocation} = viewer.company;
	const {formatMessage} = useIntl();

	const isClient = isClientUser();
	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
	const isSagePSA = hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
	const currentPath = window.location.pathname + window.location.hash;
	const canSeeFullScheduling = Util.AuthorizeViewerAccess('scheduling') && hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS);
	const canSeePlaceholders = hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.PLACEHOLDERS);
	const canSeeTimeManagement =
		Util.AuthorizeViewerAccess('team-time') && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS);

	// scheduling page access
	const hasReadOnlyFF = hasFeatureFlag('scheduling_read_only_permissions');
	const hasAllTimelinesAccess =
		canSeeFullScheduling || (hasReadOnlyFF && hasPermission(PERMISSION_TYPE.ALL_TIMELINES_VIEW_ONLY));
	const hasPeopleScheduleAccess =
		canSeeFullScheduling || (hasReadOnlyFF && hasPermission(PERMISSION_TYPE.PEOPLE_SCHEDULE_VIEW_ONLY));
	const hasDemandAccess =
		(canSeeFullScheduling && canSeePlaceholders) || (hasReadOnlyFF && hasPermission(PERMISSION_TYPE.DEMAND_VIEW_ONLY));
	const hasCapacityOverviewAccess =
		hasModule(MODULE_TYPES.CAPACITY_OVERVIEW) &&
		((canSeeFullScheduling && canSeePlaceholders) ||
			(hasReadOnlyFF && hasPermission(PERMISSION_TYPE.CAPACITY_OVERVIEW_VIEW_ONLY)));

	const canSeeTimeApproval = hasTimeApprovalAccess(viewer);
	const programsLink = hasModule(MODULE_TYPES.PROGRAMS_PLUS) ? '/programs' : '/project-groups';

	const canSeePermissions = hasModule(MODULE_TYPES.PERMISSION_MANAGEMENT);
	const hasCustomFields = hasModule(MODULE_TYPES.CUSTOM_FIELDS);

	//region routes
	const myWorkRoutes = filterMenuItems([
		{name: 'My Tasks', href: '/', trackingName: 'My Tasks', cy: 'primary-nav-my-tasks'},
		{
			name: 'My Timesheets',
			href: '/timesheets',
			trackingName: 'My Timesheets',
			visible: !isClient && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS),
			cy: 'primary-nav-my-timesheets',
		},
		// Non client users get access to 'My Scheduling' with a limited people scheduling view for just themselves.
		{
			name: formatMessage({id: 'header.my_schedule'}),
			trackingName: 'My Schedule',
			href: '/scheduling#my-schedule',
			visible: Util.AuthorizeViewerAccess('scheduling'),
			cy: 'primary-nav-my-schedule',
		},
		{
			name: 'My Expenses',
			trackingName: 'My Expenses',
			href: '/expenses',
			visible: !isClient && CompanySetupUtil.hasFinance(),
			cy: 'primary-nav-my-expenses',
		},
		{
			name: 'My Notifications',
			trackingName: 'My Notifications',
			href: '/notifications',
			cy: 'primary-nav-my-notification',
		},
	]);

	const workManagementRoutes = filterMenuItems([
		{
			name: 'All Projects',
			trackingName: 'Projects',
			href: '/projects',
			cy: 'primary-nav-projects',
		},
		{
			name: ProgramUtil.programText(formatMessage, true),
			trackingName: 'Programs',
			href: programsLink,
			visible: Util.AuthorizeViewerAccess('program'),
			cy: 'primary-nav-programs',
		},
		{
			name: 'All Timelines',
			trackingName: 'Timelines Overview',
			href: '/scheduling#all-timelines',
			lightCTA: true,
			visible: hasAllTimelinesAccess,
			cy: 'primary-nav-timelines-overview',
		},
	]);

	const resourcingRoutesCol1 = filterMenuItems([
		{
			name: 'People Schedule',
			trackingName: 'People Schedule',
			href: '/scheduling#people',
			visible: hasPeopleScheduleAccess,
			cy: 'primary-nav-people-schedule',
		},
		{
			name: 'Demand',
			trackingName: 'Placeholders',
			href: '/scheduling#demand',
			visible: (isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(viewer.company)) && hasDemandAccess,
			cy: 'primary-nav-placeholders',
		},
		{
			name: formatMessage({id: 'scheduling.menu.capacity_overview'}),
			trackingName: 'Capacity Overview',
			href: '/scheduling#capacity-overview',
			visible:
				(isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(viewer.company)) && hasCapacityOverviewAccess,
			cy: 'primary-nav-capacity',
		},
		{
			name: formatMessage({id: 'approval.timesheet_approval'}),
			trackingName: 'Timesheet Approval',
			href: '/resourcing/timesheet-approval',
			visible: canSeeTimeManagement && canSeeTimeApproval,
			cy: 'primary-nav-timesheet-approval',
		},
		{
			name: 'Timesheet Entries',
			trackingName: 'Timesheet Entries',
			href: '/resourcing/timesheet-entries',
			visible: canSeeTimeManagement,
			cy: 'primary-nav-timesheet-entries',
		},
	]);

	const canSeeInvoicing =
		(hasModule(MODULE_TYPES.FINANCE) &&
			hasPermission(PERMISSION_TYPE.INVOICE_READ) &&
			!Util.isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES)) ||
		isSagePSAWithFinancialsAccess();

	const canSeeExpenseManagement = Util.AuthorizeViewerAccess('expense-management');

	const financialRoutes = filterMenuItems([
		{
			name: 'Invoice Creation',
			trackingName: 'Invoice Creation',
			href: '/finance/creation',
			visible: canSeeInvoicing,
			cy: 'primary-nav-invoice-creation',
		},
		{
			name: 'All Invoices',
			trackingName: 'All Invoices',
			href: '/finance/invoices',
			visible: canSeeInvoicing,
			cy: 'primary-nav-all-invoices',
		},
		{
			name: formatMessage({id: 'expense_management.title'}),
			trackingName: 'Expenses',
			href: '/finance/expenses',
			visible: canSeeExpenseManagement,
			cy: 'primary-nav-expense-management',
		},
	]);
	const reportingRoutes = filterMenuItems([
		{
			name: 'Standard Reports',
			href: '/reports',
			trackingName: 'Standard Reports',
			visible: hasPermission(PERMISSION_TYPE.INSIGHTS_READ),
			cy: 'primary-nav-all-reports',
		},
		{
			name: Util.titleCase(formatMessage({id: 'common.advanced_analytics'})),
			trackingName: 'Advanced Analytics',
			href: '/advanced-analytics',
			visible: hasPermission(PERMISSION_TYPE.SISENSE_READ) && hasModule(MODULE_TYPES.SISENSE),
			cy: 'primary-nav-advanced-analytics',
		},
		{
			name: 'Unit4 Error Log',
			trackingName: 'Unit4 log',
			href: '/unit4log',
			lightCTA:
				// Only use lightCTA if we are also showing "All Reports" or "Advanced Analytics"
				hasPermission(PERMISSION_TYPE.INSIGHTS_READ) ||
				(hasPermission(PERMISSION_TYPE.SISENSE_READ) && hasModule(MODULE_TYPES.SISENSE)),
			visible: viewer.company.unit4Enabled && Util.AuthorizeViewerAccess('unit4-log'),
			cy: 'primary-nav-unit4-log',
		},
	]);

	const adminRoutes = filterMenuItems([
		{
			name: Util.titleCase(formatMessage({id: 'entity.info.details'}, {entity: formatMessage({id: 'common.company'})})),
			trackingName: 'Company Details',
			href: '/admin/details',
			visible: isAdmin,
			cy: 'primary-nav-company-details',
		},
		{
			name: formatMessage({id: 'common.resources'}),
			trackingName: 'Resources',
			href: '/admin/people',
			visible: isAdmin,
			cy: 'primary-nav-resources',
		},
		{
			name: formatMessage({id: 'common.permissions'}),
			trackingName: 'Permissions',
			href: '/admin/permissions',
			visible: isAdmin && canSeePermissions,
			cy: 'primary-nav-permissions',
		},
		{
			name: formatMessage({id: 'settings_finance.menuTitle'}),
			trackingName: 'Finance',
			href: '/admin/finance',
			visible: isAdmin && CompanySetupUtil.hasFinance(),
			cy: 'primary-nav-finance',
		},
		{
			name: Util.titleCase(
				formatMessage({id: 'settings.text.entity'}, {entity: formatMessage({id: 'common.notification'})})
			),
			trackingName: 'Notification settings',
			href: '/admin/notifications',
			visible: isAdmin && !isSagePSA,
			cy: 'primary-nav-notification-settings',
		},
		{
			name: formatMessage({id: 'common.labels'}),
			trackingName: 'Labels',
			href: '/admin/labels',
			visible: isAdmin,
			cy: 'primary-nav-labels',
		},
		{
			name: formatMessage({id: 'common.time_management'}),
			trackingName: 'Time Management',
			href: '/admin/time-management',
			visible: isAdmin && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS),
			cy: 'primary-nav-time-management',
		},
		{
			name: formatMessage({id: 'common.holidays'}),
			trackingName: 'Holidays',
			href: '/admin/holidays',
			visible: isAdmin,
			cy: 'primary-nav-holidays',
		},
		{
			name: formatMessage({id: 'settings.clients'}),
			trackingName: 'Clients',
			href: '/admin/clients',
			visible: isAdmin,
			cy: 'primary-nav-clients',
		},
		{
			name: Util.titleCase(formatMessage({id: 'entity.info.priority'}, {entity: formatMessage({id: 'common.project'})})),
			trackingName: 'Project Priority',
			href: '/admin/project-priority',
			visible: isAdmin,
			cy: 'primary-nav-project-priority',
		},
		{
			name: formatMessage({id: 'settings.api_keys.title'}),
			trackingName: 'API Keys',
			href: '/admin/api-keys',
			visible: isAdmin,
			cy: 'primary-nav-api-keys',
		},
		{
			name: formatMessage({id: 'settings.custom_fields.title'}),
			trackingName: 'Custom Fields',
			href: '/admin/custom-fields',
			visible: isAdmin && hasCustomFields,
			cy: 'primary-nav-custom-fields',
		},
		{
			name: 'Data Import',
			trackingName: 'Data Import',
			href: '/admin/data-importer',
			visible: isAdmin && hasModule(MODULE_TYPES.DATA_IMPORTER) && !isSagePSA,
			cy: 'primary-nav-data-importer',
		},
		{
			name: formatMessage({id: 'settings.sage_psa'}),
			trackingName: 'Sage PSA',
			href: '/admin/integrations/sage-intacct',
			visible: isAdmin && isSagePSA,
			cy: 'primary-nav-sage-psa',
		},
		{
			name: formatMessage({id: 'settings.integrations'}),
			trackingName: 'Integrations',
			href: '/admin/integrations',
			visible: isAdmin,
			cy: 'primary-nav-integrations',
		},
		{
			name: formatMessage({id: 'settings.setup.title'}),
			trackingName: 'Setup',
			href: '/admin/setup',
			visible: isAdmin && !isSagePSA,
			cy: 'primary-nav-setup',
		},
		{
			name: formatMessage({id: 'settings.subscription'}),
			trackingName: 'Subscription',
			href: '/admin/subscription',
			visible:
				(isAdmin &&
					!isSagePSA &&
					[TIERS.LITE, TIERS.PRO, TIERS.ENTERPRISE].includes(viewer.company.tier) &&
					!viewer.company.isCoreTier) || // Hide subscription page for core tier for now
				(isAdmin && !isSagePSA && viewer.company.tier === TIERS.CORE && hasFeatureFlag('core_tier_switch')),
		},
	]);
	//endregion

	const ColumnItem = ({children, lightCTA, ...props}) => {
		return lightCTA ? (
			<NavigationBar.LightCTAItem {...props}>{children}</NavigationBar.LightCTAItem>
		) : (
			<NavigationBar.ColumnItem {...props}>{children}</NavigationBar.ColumnItem>
		);
	};

	const ContentColumn = ({routes, ...props}) => {
		return (
			<NavigationBar.ContentColumn {...props}>
				{routes.map(route => (
					<ColumnItem key={route.name} lightCTA={route.lightCTA}>
						<Link
							to={route.href}
							component={NavigationLink}
							LinkComponent={NavigationBar.Link}
							route={route}
							data-cy={route.cy}
						>
							{route.name}
						</Link>
					</ColumnItem>
				))}
			</NavigationBar.ContentColumn>
		);
	};

	return (
		<TopNavigation.Left>
			<NavLink aria-label="homepage" to="" onClick={() => trackEvent('Navigation Menu Logo', 'Clicked')}>
				<LogoWrapper>
					<Logo size={LOGO_SIZE.SMALL} />
				</LogoWrapper>
			</NavLink>
			<NavigationBar
				onMoreClick={() =>
					trackEvent('Navigation Menu More Button', 'Clicked', {
						navigationItemType: 'Navigation Menu More Button',
					})
				}
			>
				<NavigationBar.Item
					name="My Work"
					onClick={() =>
						trackEvent('Navigation Menu My Work Button', 'Clicked', {
							navigationItemType: 'Navigation Menu Button',
						})
					}
					isActive={
						currentPath === '/' ||
						currentPath.includes('/my-profile/all-notifications') ||
						currentPath.includes('/timesheets') ||
						currentPath.includes('/expenses')
					}
					data-cy="primary-nav-my-work"
				>
					<NavigationBar.ItemContent>
						<ContentColumn routes={myWorkRoutes} />
					</NavigationBar.ItemContent>
				</NavigationBar.Item>
				<NavigationBar.Item
					name="Projects"
					onClick={() =>
						trackEvent('Navigation Menu Team Work Button', 'Clicked', {
							navigationItemType: 'Navigation Menu Button',
						})
					}
					isActive={
						workManagementRoutes.some(r => currentPath.includes(r.href)) ||
						currentPath.includes('/project/') ||
						currentPath.includes('/connected/X-')
					}
					data-cy="primary-nav-team-work"
				>
					<NavigationBar.ItemContent>
						<ContentColumn routes={workManagementRoutes} />
					</NavigationBar.ItemContent>
				</NavigationBar.Item>
				{resourcingRoutesCol1.length > 0 && (
					<NavigationBar.Item
						onClick={() =>
							trackEvent('Navigation Menu Resourcing Button', 'Clicked', {
								navigationItemType: 'Navigation Menu Button',
							})
						}
						name="Resourcing"
						isActive={resourcingRoutesCol1.some(r => currentPath.includes(r.href))}
						data-cy="primary-nav-resourcing"
					>
						<NavigationBar.ItemContent>
							{resourcingRoutesCol1.length > 0 && <ContentColumn routes={resourcingRoutesCol1} />}
						</NavigationBar.ItemContent>
					</NavigationBar.Item>
				)}
				{financialRoutes.length > 0 && !sageFinanceMenuRestricted() && (
					<NavigationBar.Item
						name="Finance"
						onClick={() =>
							trackEvent('Navigation Menu Financials Button', 'Clicked', {
								navigationItemType: 'Navigation Menu Button',
							})
						}
						isActive={financialRoutes.some(r => currentPath.includes(r.href))}
						data-cy="primary-nav-financials"
					>
						<NavigationBar.ItemContent>
							<ContentColumn routes={financialRoutes} />
						</NavigationBar.ItemContent>
					</NavigationBar.Item>
				)}
				{reportingRoutes.length > 0 && CompanySetupUtil.hasReporting() && (
					<NavigationBar.Item
						name="Reports"
						onClick={() =>
							trackEvent('Navigation Menu Reports Button', 'Clicked', {
								navigationItemType: 'Navigation Menu Button',
							})
						}
						isActive={
							reportingRoutes.some(r => currentPath.includes(r.href)) || currentPath.includes('/saved-report')
						}
						data-cy="primary-nav-reports"
					>
						<NavigationBar.ItemContent>
							<ContentColumn routes={reportingRoutes} />
						</NavigationBar.ItemContent>
					</NavigationBar.Item>
				)}
				{adminRoutes.length > 0 && (
					<NavigationBar.Item
						name="Admin"
						onClick={() =>
							trackEvent('Navigation Menu Admin Button', 'Clicked', {
								navigationItemType: 'Navigation Menu Button',
							})
						}
						isActive={adminRoutes.some(r => currentPath.includes(r.href))}
						data-cy="primary-nav-admin"
					>
						<NavigationBar.ItemContent>
							<ContentColumn routes={adminRoutes} />
						</NavigationBar.ItemContent>
					</NavigationBar.Item>
				)}
				{viewer.company.tier === TIERS.TRIAL && !isClientUser() && !isSagePSA && (
					<Link
						to="/trial-guide"
						component={NavigationLink}
						LinkComponent={NavigationBar.LinkItem}
						route={{trackingName: 'Guide'}}
						isActive={currentPath.includes('/trial-guide')}
						data-cy="primary-nav-trial-guide"
					>
						Guide
					</Link>
				)}
			</NavigationBar>
		</TopNavigation.Left>
	);
};

const PrimaryNavigationMainQuery = graphql`
	query PrimaryNavigationMain_Query {
		viewer {
			actualPersonId
			component(name: "top_navigation_main")
			...PrimaryNavigationMain_viewer
		}
	}
`;

export {PrimaryNavigationMainQuery};

export default withRouter(
	withSocketHandling(
		createFragmentContainer(PrimaryNavigationMain, {
			viewer: graphql`
				fragment PrimaryNavigationMain_viewer on Viewer {
					id
					projectOwner
					company {
						id
						useTimeApproval
						useTimeOffApproval
						unit4Enabled
						isUsingProjectAllocation
						isUsingMixedAllocation
						tier
						isCoreTier
						sageIntacctTimeRegsFromForecast
					}
				}
			`,
		})
	)
);
