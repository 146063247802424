var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
const handleBeforeUnload = (e) => {
    if (e) {
        e.preventDefault();
        e.returnValue = 'Do you want to save your changes?'; // cannot use intl here - this has to be a plain js function, besides, most browsers show their own prompts
    }
};
export const useRouterPrompt = ({ hasChanges, onSave, onSaveAs, onDiscard, ignoreChildPaths, }) => {
    const history = useHistory();
    const { location } = history;
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const removeBlocker = useCallback(() => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        history.block(() => void 0);
    }, [history]);
    useEffect(() => {
        if (hasChanges) {
            // handles when page is unloaded (refresh, close, click favourite, etc)
            // it uses the browser default prompt
            window.addEventListener('beforeunload', handleBeforeUnload);
            // internal routing allows a custom prompt to be shown
            history.block(prompt => {
                const skip = ignoreChildPaths && prompt.pathname.includes(location.pathname);
                if (!skip) {
                    setCurrentPath(prompt.pathname);
                    setShowPrompt(true);
                    return 'true';
                }
            });
        }
        else {
            removeBlocker();
        }
        return () => {
            removeBlocker();
        };
    }, [history, hasChanges]);
    const handleSave = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (onSave) {
            const onSuccess = () => {
                removeBlocker();
                history.push(currentPath);
            };
            onSave(onSuccess);
        }
    }), [currentPath, history, onSave]);
    const handleSaveAs = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (onSaveAs) {
            const onSuccess = () => {
                removeBlocker();
                history.push(currentPath);
            };
            onSaveAs(onSuccess);
        }
    }), [currentPath, history, onSaveAs]);
    const handleDiscard = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const canRoute = onDiscard ? yield Promise.resolve(onDiscard()) : true;
        if (canRoute) {
            removeBlocker();
            history.push(currentPath);
            setShowPrompt(false);
        }
    }), [currentPath, history, onDiscard]);
    const closePrompt = useCallback(() => {
        setShowPrompt(false);
    }, []);
    return { showPrompt, handleSave, handleSaveAs, handleDiscard, closePrompt };
};
