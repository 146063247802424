import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import MergeLabelsMutation from '../../../mutations/merge_labels_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {InputLabel} from 'web-components';
import DropdownV2 from '../../shared/components/dropdowns/dropdown';
import Util from '../../shared/util/util';
import {Field, ModalBody} from '../../shared/components/modals/Modal.styled';
import ColorsPicker from '../../../components/colors_picker';
import LabelEntityWarning from './label_entity_warning';
import InputFieldV2 from '../../../components/new-ui/input_field';

function pickUnique(options) {
	return new Set(options).size === 1 ? options[0] : null;
}

const MergeLabelsModal = ({labelIds, company, closeModal}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const NO_CATEGORY = formatMessage({id: 'settings_labels.no_category'});
	const allLabels = company.labels.edges.map(edge => edge.node);
	const labels = allLabels.filter(label => labelIds.has(label.id));
	const existingNames = new Set(allLabels.filter(label => !labelIds.has(label.id)).map(label => label.name));
	const [errorMessage, setErrorMessage] = useState();
	const count = labelIds.size;
	if (!labels || !labels.length) {
		return null;
	}

	const [name, setName] = useState(pickUnique(labels.map(label => label.name)));
	const [color, setColor] = useState(labels[0].color);
	const [deletionWarnings, setDeletionWarnings] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(pickUnique(labels.map(label => label.category?.id)));

	const categories = company.labelCategories.edges.map(label => label.node);
	const categoryOptions = categories.map(label => ({value: label.id, label: label.name}));
	categoryOptions.push({value: null, label: NO_CATEGORY});

	const onNameChange = newName => {
		setName(newName);
		const duplicateName = existingNames.has(newName);
		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings_labels.already_exists'}));
		} else {
			setErrorMessage(null);
		}
	};

	const update = () => {
		Util.CommitMutation(
			MergeLabelsMutation,
			{
				labelIds: Array.from(labelIds),
				name,
				color: color,
				categoryId: selectedCategory,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_labels.merge_labels_toast'}, {count, name}),
				});
			}
		);
	};

	const labelsAssignedTo = {tasks: 0, projects: 0, people: 0};
	labels.forEach(label => {
		if (label.taskCount > 0) {
			labelsAssignedTo.tasks++;
		}

		if (label.projectCount > 0) {
			labelsAssignedTo.projects++;
		}

		if (label.peopleCount > 0) {
			labelsAssignedTo.people++;
		}
	});

	const onSelectCategory = option => {
		const categoryId = option.value;
		setSelectedCategory(categoryId);
		const warnings = [];
		const category = categories.find(category => category.id === categoryId);

		if (category) {
			if (!category.allowOnPeople && labelsAssignedTo.people > 0) {
				warnings.push({
					entity: formatMessage({id: 'settings_labels.not_allowed_people'}),
					labelCount: labelsAssignedTo.people,
				});
			}
			if (!category.allowOnTasks && labelsAssignedTo.tasks > 0) {
				warnings.push({
					entity: formatMessage({id: 'settings_labels.not_allowed_tasks'}),
					labelCount: labelsAssignedTo.tasks,
				});
			}
			if (!category.allowOnProjects && labelsAssignedTo.projects > 0) {
				warnings.push({
					entity: formatMessage({id: 'settings_labels.not_allowed_projects'}),
					labelCount: labelsAssignedTo.projects,
				});
			}
		}
		setDeletionWarnings(warnings);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			headerText={formatMessage({id: 'settings_labels.merge_labels'}, {count})}
			modalWidth={MODAL_WIDTH.SMALL}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: update,
					disabled: !name || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<div>
					<ModalBody>
						<Field>
							<InputFieldV2
								value={name}
								cy={'label-name'}
								id="labelName"
								label={formatMessage({id: 'common.name'})}
								placeholder={formatMessage({id: 'common.name_placeholder'})}
								type="text"
								onChange={onNameChange}
								errorMessage={errorMessage}
								invalidInput={!!errorMessage}
								required={true}
								maxLength={191}
							/>
						</Field>
						<Field>
							<InputLabel
								text={formatMessage({id: 'common.color'})}
								child={<ColorsPicker value={color} onColorSelect={setColor} size={20} usePortal />}
							/>
						</Field>
						<Field>
							<DropdownV2
								options={categoryOptions}
								onChange={onSelectCategory}
								value={selectedCategory}
								label={formatMessage({id: 'settings_labels.category'})}
								placeholder={NO_CATEGORY}
								inputName={'category-input'}
								inputCy={'category'}
								listDataCy={'category'}
							/>
						</Field>
						<Field>
							{deletionWarnings.map(({entity, labelCount}) => (
								<LabelEntityWarning
									updated
									itemName={formatMessage({id: 'settings_labels.warning_deletion'}, {entity})}
									itemWarning={formatMessage(
										{id: 'settings_labels.warning_multiple_deletions'},
										{labelCount, entity}
									)}
									key={'warning-' + entity}
									cy={`warning-${entity}`}
								/>
							))}
						</Field>
					</ModalBody>
				</div>
			}
		/>
	);
};

const mergeLabelsModalQuery = graphql`
	query MergeLabelsModal_Query {
		viewer {
			actualPersonId
			component(name: "merge_labels_modal")
			company {
				...MergeLabelsModal_company
			}
		}
	}
`;

export {mergeLabelsModalQuery};

export default createFragmentContainer(MergeLabelsModal, {
	company: graphql`
		fragment MergeLabelsModal_company on Company {
			id
			createdAt
			labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
				edges {
					node {
						id
						name
						allowOnTasks
						allowOnProjects
						allowOnPeople
					}
				}
			}
			labels(first: 10000) @connection(key: "Company_labels") {
				edges {
					node {
						id
						name
						color
						taskCount
						projectCount
						peopleCount
						category {
							id
						}
					}
				}
			}
		}
	`,
});
