import React, { useState } from 'react';
import { Checkbox, DeprecatedCreativeModal as Modal, FlexRow, Label, Text, TextField } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
const SaveReportAsModal = ({ saveReportAs, report, closeModal }) => {
    const intl = useIntl();
    const initialName = report.name + ', Duplicate';
    const [name, setName] = useState(initialName);
    const [privateAccess, setPrivateAccess] = useState(report.privateAccess);
    return (React.createElement(Modal, { title: intl.formatMessage({ id: 'saved_report.new_report' }), onCreateHandler: () => saveReportAs(closeModal, Object.assign(Object.assign({}, report), { name, privateAccess })), creativeButtonText: intl.formatMessage({ id: 'saved_report.save_as' }), size: "m", closeModal: closeModal, replicateDesignSystem: true },
        React.createElement(TextField, { label: "Report Name", value: name, onValueChange: setName }),
        React.createElement(Label, null, intl.formatMessage({ id: 'report.share-company-label' })),
        React.createElement(FlexRow, null,
            React.createElement(Checkbox, { checked: !privateAccess, onChange: value => setPrivateAccess(!value) }),
            React.createElement(Text, null, intl.formatMessage({ id: 'report.private_access.description' })))));
};
export default SaveReportAsModal;
